<template>
    <div class="view" v-infinite-scroll="getData">
        <header id="fx-head">
            <i class="hwiconfont icon-fanhui" @click="close()"></i>
            <el-input placeholder="输入关键词搜索" v-model="kw" clearable></el-input>
            <el-button @click="getData(1)" style="margin: 0 10px;" type="primary" size="mini" round>搜索</el-button>
        </header>
        <dyList :list="list" h5="1"></dyList>
        <p class="more-btn" v-if="list.length">{{total==list.length ? '暂无更多':'加载中'}}</p>
    </div>
</template>

<script>
    import {
        mapGetters,
        mapMutations
    } from 'vuex';
    import dyList from "@components/common/dy-list";
    export default {
        components: {
            dyList,
        },
        data() {
            return {
                kw: '',
                list: [],
                total: 0,
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            // ...mapMutations([])
            // 获取列表
            async getData(first) {
                if (!this.kw) return;
                let param = {
                    longitude: this.$y_getKey("lon"),
                    latitude: this.$y_getKey("lat"),
                    type: 0, // 动态类型
                    user_id: this.$y_getKey("userid") || 8,
                    keyword: this.kw,
                };
                if (first) {
                    param.page = 1;
                    this.list = [];
                }
                let {
                    list,
                    total
                } = await this.$y_list("api/dymanic/lists", this.list, param);
                this.list = list;
                this.total = total;
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
        }
    };
</script>

<style lang="less" scoped>
    #fx-head {
        position: sticky;
        position: -webkit-sticky;
        background: #fff;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        padding: 10px 0;
        z-index: 1;
    }
</style>